import { render, staticRenderFns } from "./examesEncerrados.vue?vue&type=template&id=04c007b6&scoped=true"
import script from "./examesEncerrados.vue?vue&type=script&lang=js"
export * from "./examesEncerrados.vue?vue&type=script&lang=js"
import style0 from "./examesEncerrados.vue?vue&type=style&index=0&id=04c007b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c007b6",
  null
  
)

export default component.exports