<template>
  <v-container fluid>
    <v-row class="pl-16">
      <v-col cols="4">
        <v-row class="mt-5 pl-14">
          <Menu></Menu>
          <div class="text-h4 font-weight-bold">Exames encerrados</div>
        </v-row>
        <v-row class="pl-14">
          <div class="light-blue-rectangle mt-3"></div>
        </v-row>
      </v-col>

      <v-col cols="8" class="justify-space-around pl-16">
        <v-row>
          <v-col cols="6" class="mt-10 pl-16">
            <date-picker style="max-width: 150px" v-model="params.dataInicio" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data de início"
                         lang="pt-br"></date-picker>
            <date-picker style="max-width: 150px"  v-model="params.dataFinal" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data final"
                         lang="pt-br"></date-picker>
          </v-col>
          <v-col cols="6">
            <v-list-item class="mt-6 justify-end pr-16 mr-16 pt-2">
              <div class="text-center">
                <v-dialog v-model="modal"  transition="dialog-top-transition" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="modal = true" v-bind="attrs" v-on="on">
                      <v-icon title="Filtro">mdi mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Filtros
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row class="justify-space-around pt-2">
                        <v-col cols="5">
                          <v-select v-model="cnpjsSelecionados" :items="cnpjs" item-text="cnpj" label="Laboratórios" multiple chips hint="Selecione os laboratórios que serão exibidos" persistent-hint></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="modal = false">
                        Pesquisar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-7 pl-14">
      <v-col cols="12">
        <div class="pl-8 pr-8">
          <h3 class="chart-title centered">Exames encerrados por classe de tempo</h3>
          <div id="examesEncerradosPorClasseDeTempo"></div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-7 pl-14">
      <v-col cols="12">
      <div class="pl-8 pr-8">
        <h3 class="chart-title centered">Exames encerrados por mês</h3>
        <div id="examesEncerradosPorMes"></div>
      </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/plugins/api";
import {GoogleCharts} from "google-charts";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import Menu from "@/components/Menu.vue";

export default {
  name: "examesEncerrados",
  components: {Menu, DatePicker },

  data(){
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let formattedDate = currentDate.toLocaleDateString();
    return {
      params: {
        cnpj: [],
        dataInicio: formattedDate,
        dataFinal: new Date().toLocaleDateString('pt-br')
      },
      cnpjs: [],
      cnpjsSelecionados: [],
      dataLiberacao: [],
      modal: false
    }
  },

  created() {
    this.listaDeLaboratorios();
    addEventListener("resize", () => {
      this.larguraTable = document.getElementById('examesEncerradosPorClasseDeTempo').offsetWidth;
      this.drawChartExamesEncerradosPorClasseDeTempo();
      this.drawChartExamesEncerradosPorMes();
    });
  },

  mounted() {
    this.larguraTable = document.getElementById('examesEncerradosPorClasseDeTempo').offsetWidth;
  },

  watch: {
    'params.dataInicio': 'loadData',
    'params.dataFinal': 'loadData',
    cnpjsSelecionados: {
      handler(newValue) {
        // Verificar se o valor atual é diferente do valor armazenado
        const storedCNPJs = JSON.parse(localStorage.getItem('selectedCNPJs'));
        if (JSON.stringify(newValue) !== JSON.stringify(storedCNPJs)) {
          localStorage.setItem('selectedCNPJs', JSON.stringify(newValue));
          this.loadData();
        }
      },
    }
  },

  methods: {
    listaDeLaboratorios(){
      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
        this.primeiroCNPj = this.cnpjs[0].cnpj
        this.loadData();
      });
    },

    loadData() {
      const selectedCNPJs = localStorage.getItem('selectedCNPJs');

      if (!selectedCNPJs) {
        this.params.cnpj = [this.primeiroCNPj];
        this.cnpjsSelecionados = this.params.cnpj;
      } else {
        this.params.cnpj = JSON.parse(selectedCNPJs);
        this.cnpjsSelecionados = this.params.cnpj
      }

      api.post('api/examesEncerrados/examesEncerradosPorMes', this.params).then(response => {
        this.examesEncerradosPorMes = response.data;
        this.drawChartExamesEncerradosPorMes();
      });

      api.post('/api/examesEncerrados/classesDeTempoPorCNPJ/', this.params).then(response => {
        this.classesTempo = response.data;
        api.post('api/examesEncerrados/examesEncerradosPorClasseDeTempo', this.params).then(response => {
          this.examesEncerradosPorClasseDeTempo = response.data;
          this.drawChartExamesEncerradosPorClasseDeTempo();
        });
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      });
    },

    drawChartExamesEncerradosPorMes (){
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Data de liberacao');
        data.addColumn('number', 'Quantidade de exames');

        Object.keys(this.examesEncerradosPorMes).forEach(dataLiberacao => {
          const item = this.examesEncerradosPorMes[dataLiberacao];
          item.forEach(item => {
            data.addRow([item.dataLiberacao, item.qtdExames]);
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: { color: '#607d8b', count:5 },
            textStyle: { color: 'white', fontSize: '12', bold: true},
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent',
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '14', bold: true} },
          colors: ["#00bcd4","#009688","#4caf50","#8bc34a","#cddc39"],
          chartArea: {
            backgroundColor: "transparent",
            width: '80%',
            height: '80%'
          },
          height:(this.larguraTable / 7), // example height, to make the demo charts equal size
          width: this.larguraTable,
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions',
        };

        var chart = new GoogleCharts.api.visualization.ColumnChart(
            document.getElementById('examesEncerradosPorMes')
        );
        chart.draw(data, options);
      });
    },

    drawChartExamesEncerradosPorClasseDeTempo() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês');
        Object.keys(this.classesTempo).forEach(classeTempo => {
          const nomesPatologista = this.classesTempo[classeTempo].map(obj => obj.classeTempo);
          nomesPatologista.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

        this.examesEncerradosPorClasseDeTempo.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });

        const options = {
          chart: {
            title: 'Quantidade de exames mensal',
            subtitle: 'Estatísticas por mês',
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: { color: '#b0bec5'},
            gridlines: { count:0 },
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent',
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '12'} },
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraTable / 7) + 50, // example height, to make the demo charts equal size
          width: this.larguraTable,
          bar: {groupWidth: "100" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions',

          curveType: 'function',
        };

        const chart = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('examesEncerradosPorClasseDeTempo'));
        chart.draw(data, options);
      });
    }
  },

}

</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
}
.light-blue-rectangle {
  width: 390px;
  height: 10px;
  background-color: #2196f3;
}
</style>